.calendario {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-direction: column;

}

.mes {
    display: flex;
    align-items: center;

}

.tabla {
    table-layout: fixed;
    width: 100%;
    max-width: 500px;
    height: 500px;
}

.dia {
    border: 1px;
    border-color: black;
    border-style: solid;
}


th {
    vertical-align: top !important;
}

.botonIzq {
    border-radius: 10px;
    background-color: #4CAF50;
    /* color de fondo del botón */
    border: none;
    /* eliminamos el borde por defecto */
    color: white;
    /* color del texto del botón */
    text-align: center;
    /* alineación del texto */
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 20px;
    cursor: pointer;
}

.botonDer {
    border-radius: 10px;
    background-color: #4CAF50;
    /* color de fondo del botón */
    border: none;
    /* eliminamos el borde por defecto */
    color: white;
    /* color del texto del botón */
    text-align: center;
    /* alineación del texto */
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 20px;
    cursor: pointer;
}
.mesTexto{
    font-weight: bold;
}
